import React, { createContext, useContext, useState, ReactNode } from 'react';

interface TokenContextProps {
  token: string | null;
  setToken: (token: string | null) => void;
  clearCache: () => void;
}

const TokenContext = createContext<TokenContextProps | undefined>(undefined);

interface TokenProviderProps {
  children: ReactNode;
}

export const TokenProvider: React.FC<TokenProviderProps> = ({ children }) => { 
  const [token, setToken] = useState<string | null>(null);

  const clearCache = () => {
    setToken(null);
  };

  return (
    <TokenContext.Provider value={{ token, setToken, clearCache }}>
      {children}
    </TokenContext.Provider>
  );
};

export const useToken = () => {
  const context = useContext(TokenContext);
  if (!context) {
    throw new Error('useToken must be used within a TokenProvider');
  }
  return context;
};
