import engrenagem from '../images/engrenagem.svg';
function AnalyzeDataLoading (){

    return (
        <div className="msgs">
            <div className="engrenagem">
                <img src={engrenagem}></img>
            </div>
            <h4>Estamos verificando seus dados.</h4>
            <p className="mb-0">Por favor, aguarde nesta página.</p>
        </div>
    );
}

export default AnalyzeDataLoading;