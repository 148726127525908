import React, { useState } from "react";
import { ClientView } from "../../models/client-view";
import { Cooperative } from "../../models/cooperative";
import { useSicrediContext } from "../../context/SicrediContext";

interface Props {
  cliente?: ClientView;
  cooperatives?: Array<Cooperative>;
  onNext: () => void;
  onPrev: () => void;
}

function Etapa2(props: Props) {
  const { clientView, setClientView } = useSicrediContext();
  const [isCpfValid, setIsCpfValid] = useState(true);
  const [isContaValid, setIsContaValid] = useState(true);
  const [isCooperativeValid, setIsCooperativeValid] = useState(true);

  const handleCpfChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var value = event.target.value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
    const newClientView = { ...clientView, fcpfCgc: value };
    setClientView(newClientView);
    setIsCpfValid(true); // Reset validation state
  };

  const handleContaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
          .replace(/\D/g, '') // Substitui qualquer caracter que não seja número por nada
          .replace(/(\d{5})(\d)/, '$1-$2') // Captura 2 grupos de números: 5 e 1, e insere um traço entre eles
          .replace(/(-\d{1})\d+?$/, '$1'); // Captura 1 número seguido de um traço e não permite mais números após o traço

    const newClientView = { ...clientView, fconta: value };
    setClientView(newClientView);
    setIsContaValid(true); // Reset validation state
  };

  const handleCooperativeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newClientView = { ...clientView, fcooperativa: event.target.value };
    setClientView(newClientView);
    setIsCooperativeValid(true); // Reset validation state
  };

  const next = () => {
    let isValid = true;

    if (clientView.fcpfCgc!.trim() === "") {
      setIsCpfValid(false);
      isValid = false;
    }

    if (clientView.fconta!.trim() === "") {
      setIsContaValid(false);
      isValid = false;
    }

    if (!clientView.fcooperativa) {
      setIsCooperativeValid(false);
      isValid = false;
    }

    if (isValid) {
      props.onNext();
    }
  };

  return (
    <>
      <div>        
        <div className="form-floating mb-10">
          <input
            id="cpfInputJorn2"
            type="text"
            className={`form-control ${
              isCpfValid ? "" : "is-invalid"
            }`}
            placeholder="CPF"
            maxLength={14}
            required
            value={clientView.fcpfCgc != null ? clientView.fcpfCgc : ""}
            onChange={handleCpfChange}
          />
          <label>CPF</label>
          <div className="invalid-feedback">Informe um CPF válido.</div>
        </div>

        <div className="form-floating mb-10">
          <input
            type="text"
            className={`form-control ${
              isContaValid ? "" : "is-invalid"
            }`}
            placeholder="Número da conta (com dígito)"
            maxLength={7}
            required
            value={clientView.fconta!}
            onChange={handleContaChange}
          />
          <label>Número da conta (com dígito)</label>
          <div className="invalid-feedback">Informe o número da conta.</div>
        </div>

        <div className="mb-10">
          <select
            className={`form-select ${
              isCooperativeValid ? "" : "is-invalid"
            }`}
            value={
              clientView.fcooperativa != null ? clientView.fcooperativa : ""
            }
            onChange={handleCooperativeChange}
          >
            <option value="" disabled>
              Selecione uma cooperativa
            </option>
            {props.cooperatives!.map((cooperative) => (
              <option
                key={cooperative.id}
                value={cooperative.id?.toString()}
              >
                {cooperative.id!.toString() +
                  " - " +
                  cooperative.cooperativeName}
              </option>
            ))}
          </select>
          <div className="invalid-feedback">Informe a Cooperativa.</div>
        </div>
      </div>

      <div className="row mb-10">
        <div className="col-6 solicita-button">
          <button
            type="button"
            className="btn btn-back w-100"
            onClick={props.onPrev}
          >
            Anterior
          </button>
        </div>
        <div className="col-6 solicita-button">
          <button
            type="button"
            className="btn btn-primary w-100"
            onClick={next}
          >
            Próximo
          </button>
        </div>
      </div>
    </>
  );
}

export default Etapa2;
