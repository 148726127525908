import { useEffect, useState } from "react";
import { ClientView } from "../../models/client-view";
import { State } from "../../models/state";
import IntegrationService from "../../services/integration";
import { useSicrediContext } from "../../context/SicrediContext";

interface Props {
    cliente?: ClientView;
    states?: State[];
    onNext: () => void;
    onPrev: () => void;
}

function Etapa4 (param: Props){
    const { leadView, setLeadView, clientView, setClientView } = useSicrediContext();
    const [cities, setCities] = useState<any>([])
    const [showAddress, setAddress] = useState(true);

    const handleState = (
        event: React.ChangeEvent<HTMLSelectElement>
      ) => {
        let value = event.target.value;
        const newClientView = { ...clientView, fuf: value };
        setClientView(newClientView);
        IntegrationService.getAllCities(event.target.value).then(response => {
            let cities = response.data;
            setCities(cities);
        })
    };

    const handleCityChange = (event : React.ChangeEvent<HTMLSelectElement>) => {
        let value = event.target.value;
        const newClientView = { ...clientView, fmunic: value };
        setClientView(newClientView);
    };

    const handleCEPChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        const newClientView = { ...clientView, fcep: value };
        setClientView(newClientView);
    };

    const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        const newClientView = { ...clientView, fendereco: value };
        setClientView(newClientView);
    };

    const handleDistrictChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        const newClientView = { ...clientView, fbairro: value };
        setClientView(newClientView);
    };

    const handleDeliveryPlaceChange = (event: React.ChangeEvent<HTMLInputElement>) => {      
        const valor = event.target.value;
        const newLeadView = {...leadView, deliveryPlace: valor}  
        setLeadView(newLeadView);
        return valor;
    };

    useEffect(() => {
        IntegrationService.getAllCities(param.cliente!.fuf!).then(response => {
            let cities = response.data;
            setCities(cities);
        })
    }, []);

    useEffect(() => {
        if (leadView.deliveryPlace === 'Agencia') {
            setAddress(false);
        }else{
            setAddress(true);
        }
        console.log(leadView.deliveryPlace)
    }, [leadView.deliveryPlace]);

    return (
        <>
            <div className="mb-4">
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="LocalRetirada"
                        id="LocalRetiradaEndereco"
                        value="Residencial"
                        required
                        checked={leadView.deliveryPlace === 'Residencial'}
                        onChange={e => handleDeliveryPlaceChange(e)}
                    />
                    <label className="form-check-label" htmlFor="LocalRetiradaEndereco">Endereço já cadastrado</label>
                </div>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="LocalRetirada"
                        id="LocalRetiradaCooperativa"
                        value="Agencia"
                        checked={leadView.deliveryPlace === 'Agencia'}
                        onChange={e => handleDeliveryPlaceChange(e)}
                    />
                    <label className="form-check-label" htmlFor="LocalRetiradaCooperativa">Retirar na minha cooperativa</label>
                </div>
            </div>
            {showAddress &&
                <div>            
                    <div className="form-floating mb-10">
                        <input
                            id="journey22CEP"
                            type="text"
                            className="form-control"
                            placeholder="CEP"
                            maxLength={9}
                            value={param.cliente!.fcep || ''}
                            onChange={e => handleCEPChange(e)}
                            />
                        <label htmlFor="journey22CEP">CEP</label>
                        <span></span>
                        <div className="invalid-feedback">
                            Informe um CEP.
                        </div>
                    </div>
                    <div className="form-floating mb-10">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Endereço"
                            value={param.cliente!.fendereco || ''}
                            onChange={e => handleAddressChange(e)}
                            />
                        <label htmlFor="floatingInputName">Endereço</label>
                        <span></span>
                        <div className="invalid-feedback">
                            Informe um Endereço.
                        </div>
                    </div>
                    <div className="form-floating mb-10">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Bairro"
                            value={param.cliente!.fbairro || ''}
                            onChange={e => handleDistrictChange(e)}
                            />
                        <label htmlFor="floatingInputName">Bairro</label>
                        <span></span>
                        <div className="invalid-feedback">
                            Informe um Bairro.
                        </div>
                    </div>
                    <div className="row mb-10">
                        <div className="col-4 col-md-3">
                            <select
                                className="form-select"
                                required
                                defaultValue={
                                    param.cliente?.fuf != null ? param.cliente?.fuf : ""
                                }
                                onChange={e => handleState(e)}
                            >
                                <option value="" disabled>
                                Selecione um estado
                                </option>
                                {param.states!.map((state) => (
                                <option
                                    key={state.uf}
                                    value={state.uf!}
                                >
                                    {state.uf}
                                </option>
                                ))}
                            </select>                        
                        </div>
                        <div className="col">
                            <select
                                id="citySelectJ2"
                                className="form-select"
                                value={
                                    param.cliente?.fmunic != null ? param.cliente?.fmunic : ""
                                }
                                onChange={e => handleCityChange(e)}                         
                                required   
                                >
                                <option value="" disabled>
                                Selecione uma cidade
                                </option>
                                {cities!.map((city : any) => (
                                <option
                                    key={city.nome!.toUpperCase()}
                                    value={city.nome!.toUpperCase()}
                                >
                                    {city.nome}
                                </option>
                                ))}
                            </select>
                            <div className="invalid-feedback">
                                Informe uma cidade.
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="row mb-10">
                <div className="col-6 solicita-button">
                    <button type="button" className="btn btn-back w-100" onClick={param.onPrev}>Anterior</button>
                </div>
                <div className="col-6 solicita-button">
                    <button type="button" className="btn btn-primary w-100" onClick={param.onNext}>Próximo</button>
                </div>
            </div>
        </>
    );
}

export default Etapa4;