import { useState } from 'react';
function CookiesPolicy (){
    const SetCookiesPolicy = () =>{
        setCookiesPolicy(false);
    }

    const [cookiesPolicy, setCookiesPolicy] = useState(true);

    return (
        <div>
            {cookiesPolicy && (
                <div className="privacy">
                    <div className="container-xxl">
                        <div className="row">
                            <div className="col-12 col-md-8 col-lg-8">
                                <p>Nosso site salva seu histórico de uso para proporcionar uma melhor experiência. Ao continuar navegando você concorda com a nossa <a href="https://www.sicredi.com.br/site/protecao-e-privacidade/" title="política de cookies e privacidade">política de cookies e privacidade</a>.</p>
                            </div>
                            <div className="col-12 col-md-4 col-lg-2 offset-lg-2 text-center text-md-end">
                                <button onClick={SetCookiesPolicy} type="button" className="btn btn-primary w-100">Ok, entendi!</button>
                            </div>
                        </div>
                    </div>
                </div>)
            }
        </div>
        
    );
}

export default CookiesPolicy;