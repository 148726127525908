import './Home.css';
import logoSicredi from '../../images/logo-sicredi.svg';
import imgCartaoTopo from '../../images/img_cartoes_topo.png';
import imgInvestimentos from '../../images/img-investimentos.png';
import logoFooter from '../../images/Sicredi_logo-footer.png';
import imgQrCode from '../../images/qr-code.png';
import imgCartaoPlatinum from '../../images/img_cartao_platinum.png';
import imgCartaoGold from '../../images/cartao-gold-sicredi.png';
import imgCartaoBlack from '../../images/cartao-black.png';
import imgCartoesDiferenca from '../../images/img_cartoes_diferenca.png'
import ArrowDropUpCircle from '../../images/arrow-dropup-circle.svg'
import MultiStepProgressBar from '../../components/MultiStepProgressBar/MultiStepProgressBar';
import { SetStateAction, useEffect, useState, useRef } from 'react';

import MajoracaoEtapa1 from '../JourneyMajoracao/Etapa1';
import NewRequestEtapa1 from '../JourneyNewRequest/Etapa1';
import JourneyEtapa1 from '../Journey/Etapa1';
import JourneyEtapa2 from '../Journey/Etapa2';
import JourneyEtapa4 from '../Journey/Etapa4';
import JourneyEtapa5 from '../Journey/Etapa5';
import VerifySMS from '../Journey/VerifySMS';

import IntegrationService from '../../services/integration';
import { useSicrediContext } from '../../context/SicrediContext';
import { ClientView } from '../../models/client-view';
import DeniedRequest from '../Finish/DeniedRequest';
import SuccessRequest from '../Finish/SuccessRequest';
import SendSucess from '../Finish/SendSuccess';
import AnalyzeDataLoading from '../AnalyzeDataLoading';
import Instability from '../Finish/Instability';
import NotLocalized from '../Finish/NotLocalized';
import AccountExist from '../Finish/AccountExist';
import AccountFinish from '../Finish/AccountFinish';
import NotificationLastStep from '../Finish/NotificationLastStep';
import { Lead } from '../../models/lead';
import { Account } from '../../models/account';
import CookiesPolicy from '../CookiesPolicy';
import { DueDate } from '../../models/duedate';
import { Card } from '../../models/card';
import { matchLeadWithAccount } from '../../Utils/Utils';
import { useToken } from '../../context/TokenContext';


function Index(){
    const { clientView, setClientView, leadView, setLeadView, validateSMS, setValidateSMS, haveAccount, setAccounts } = useSicrediContext();
    const [cooperativesView, setCooperativesView] = useState();
    const [agencyView, setAgencyView] = useState();
    const [dueDateView, setDueDateView] = useState(Array<DueDate>);
    const [stateView, setStateView] = useState();
    const [cardView, setCardView] = useState(Array<Card>);
    const [verifyAcc, setVerifyAcc] = useState(0);
    const [haveCard, setHaveCard] = useState(false);
    const [isInstability, setIsInstability] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [firstStep, setFirstStep] = useState(true);
    const [requestSMS, setRequestSMS] = useState(false);
    const [finishResult, setFinishResult] = useState(0);
    const [lastStep, setLastStep] = useState(false);
    const { token, setToken } = useToken();
    
    const finishForm = () =>{
        console.log(leadView)
        IntegrationService.addLead(leadView, token!)
            .then((response : any) => {
                console.log(response.data)
                if(response.data == '1' || response.data == '-1'){       
                    setFinishResult(response.data);
                }else {
                    setIsInstability(true);
                }
            }).catch((error:  any) => {
                setIsInstability(true);
            });
    }
    const [step, setStep] = useState(1);

    const nextStep = () => {
        setFirstStep(false)
        setStep(step + 1);        
    };
    
    const prevStep = () => {
        setLastStep(false);
        if(step == 4){
            setStep(step - 2);
            return;
        }
        setStep(step - 1);
    };

    useEffect(() => {
        switch (step){
            case 1:
                initialState();
                break;
            case 2:
                setHaveCard(false);
                setVerifyAcc(0);
                break;
            case 3:  
                initialState();
                setIsLoading(true);
                validateAccount();
                setValidateSMS(false);
                setRequestSMS(true);
                setIsLoading(false); 
                break;
            case 4:
                if(validateSMS){
                    getAccount();
                    getCards();
                    setRequestSMS(false);
                }
                setValidateSMS(false);
                break;
            case 5:
                break;
            case 6:
                setLastStep(true);
                break;
            default:
                //setConfirmButtons(false);
                break;
        }
    }, [step]);

    useEffect(() => {
        if(!validateSMS && token && verifyAcc){
            sendSMS();
        }
    }, [token, verifyAcc]);
    
    useEffect(() => {
        retriveInitialValues();
    }, []);

    useEffect(() => {
        if(validateSMS){
            setIsLoading(true);
            nextStep();
        }
    }, [validateSMS]);

    const sendSMS = () => {
        IntegrationService.sendSMS(clientView.fcpfCgc!, token!)
        .then((response: any) => {
            if(!response){
                setIsInstability(true);
            }
        }).catch((reason : any)=> {
            console.log(reason);
            setIsInstability(true);
        })
    }

    const getAccount = () =>{   
        if(clientView.fcpfCgc){
            IntegrationService.getAllAccount(token!)
                .then((response: any) => {
                    let accounts = response.data;
                    if(accounts && accounts.length > 0){
                        let acc = accounts[0];
                        var lead = matchLeadWithAccount(leadView, acc);
                        setLeadView(lead);
                        setClientView(acc);
                        setAccounts(accounts);
                        if(acc.fcartao){
                            setHaveCard(true);
                        }else{
                            setHaveCard(false);
                        }
                        setIsLoading(false);    
                    }
                    
                });
        }
    }

    const getCards = () => {
        IntegrationService.getAllCards(token!)
            .then((response: any) => {
                setCardView(response.data);
            })
            .catch(() =>{
                setIsInstability(true);
            });
    }

    const initialState = () => {
        setVerifyAcc(0);
        setHaveCard(false);
        setIsInstability(false);
        setIsLoading(false);
        setRequestSMS(false);
        setFirstStep(true);
        setLastStep(false);
        setFinishResult(0);
    }

    const validateAccount = () => {
        setVerifyAcc(0);
        IntegrationService.verifyAccount(clientView.fcpfCgc!, clientView.fconta!, clientView.fcooperativa!)
            .then((response : any) => {
                if(response.data.token)
                    setToken(response.data.token);

                setVerifyAcc(response.data.data);                
            }).catch((reason: any) =>{
                console.log(reason);
            })
    }
    
    const retriveInitialValues = () => {
        setIsLoading(true);
        Promise.all([
            IntegrationService.getAllAgency(),
            IntegrationService.getAllCooperative(),
            IntegrationService.getAllDueDates(),
            IntegrationService.getAllStates()
        ]).then((responses: any[]) => {
            setAgencyView(responses[0].data);
            setCooperativesView(responses[1].data);
            setDueDateView(responses[2].data);
            setStateView(responses[3].data);
            setIsLoading(false);
        }).catch(() => {
            setIsInstability(true);
            setIsLoading(false);
        });
    };

    // console.log(step, ' ', verifyAcc, ' ', haveCard)
    return(
        <div >
            <CookiesPolicy/>            
            <section className="section-header">
                <div className="container-xxl">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="row">
                                <div className="col-12">
                                    <div className="logo">
                                        <img src={logoSicredi} alt="logo Sicredi" title="Logo Sicredi" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-10">
                                    <h1>Quer ter o Cartão de crédito preferido dos brasileiros?<sup>*</sup></h1>
                                    <h2>No Sicredi você tem.</h2>
                                </div>
                                <div className="col-12 col-md-12">
                                    <img className="img-fluid" src={imgCartaoTopo} alt="imagem Cartões Sicredi" title="Cartões Sicredi" />
                                </div>
                                <div className="col-12">
                                    <p className="small"><i>*Pesquisa Nacional de Cartões de Crédito (PNCC)</i></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="card-form">
                                {isLoading && <AnalyzeDataLoading/>}
                                {isInstability && <Instability/>}
                                {verifyAcc == -1  && <AccountExist/>}
                                {verifyAcc == 2 && <NotLocalized/>}
                                {verifyAcc == 3 && <AccountFinish/>}
                                {verifyAcc == 4 && <Instability/>}
                                {finishResult == 1 && <SendSucess/>}
                                {finishResult == -1 && <DeniedRequest/>}
                                
                                {!isInstability && !isLoading && finishResult == 0 && (
                                    <div id="lpForm" className="clientForm needs-validation">
                                        {(verifyAcc == 0 || verifyAcc == 1) && !requestSMS && !haveCard && (
                                            <div>
                                                <h4 id="Titulo">PEÇA JÁ SEU CARTÃO</h4>
                                                <p className="text-center">Preencha o formulário.</p>
                                            </div>
                                        )}
                                        {(verifyAcc == 0 || verifyAcc == 1) && !requestSMS && haveCard && (
                                            <div>
                                                <h4 id="Titulo">SEU CARTÃO</h4>
                                                {/* <p className="text-center">Informe o valor:</p> */}
                                            </div>
                                        )}
                                        {(verifyAcc == 0 || verifyAcc == 1) && requestSMS && (
                                            <div>
                                                <h4 id="Titulo">Autenticação em dois fatores</h4>
                                                <p className="text-center"></p>
                                            </div>
                                        )}
                                        
                                        {step > 3 && (
                                            <MultiStepProgressBar step={step}/>
                                        )}
                                        <div>
                                            {step === 1 && <JourneyEtapa1 cliente={clientView} onNext={nextStep}/>}
                                            {step === 2 && <JourneyEtapa2 cliente={clientView} cooperatives={cooperativesView} onNext={nextStep} onPrev={prevStep}  />}
                                            {step === 3 && verifyAcc == 1 && requestSMS && <VerifySMS/>}
                                            {step === 4 && verifyAcc == 1 && haveCard && <MajoracaoEtapa1 onNext={nextStep} onPrev={prevStep} />}
                                            {step === 4 && verifyAcc == 1 && !haveCard && <NewRequestEtapa1 cards={cardView!} dueDates={dueDateView!} onNext={nextStep} onPrev={prevStep} />}
                                            {step === 5 && verifyAcc == 1 && <JourneyEtapa4 cliente={clientView} onNext={nextStep} onPrev={prevStep} states={stateView} />}
                                            {step === 6 && verifyAcc == 1 && <JourneyEtapa5 cliente={clientView} onFinish={finishForm} onPrev={prevStep} />}
                                        </div>                                    
                                    </div>
                                )}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
            <section className="section-cards">
                <div className="container-xxl">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6 col-xl-3 position-relative">
                            <div className="text-top-left">
                                <h3>Cartões Sicredi. Para fazer compras e fazer a diferença.</h3>
                                <span>Aqui, além de cliente você também é dono e participa de todas as decisões.</span>
                            </div>
                            <div className="mt-3 text-bottom-right d-none d-lg-block d-xl-none d-xxl-none">
                                <h3>O que sua conta Sicredi pode fazer:</h3>
                                <ul>
                                    <li>Render um mundo melhor</li>
                                    <li>Desenvolver sua região</li>
                                    <li>Te ajudar em suas conquistas</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6  col-xl-10 offset-xl-1">
                            <img className="img-fluid" src={imgCartoesDiferenca} alt="Imagem Cartões" title="" />
                            
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 offset-xl-7 col-xl-4 position-relative d-block d-lg-none d-xl-block d-xxl-block">
                            <div className="text-bottom-right">
                                <h3>O que sua conta Sicredi pode fazer:</h3>
                                <ul>
                                    <li>Render um mundo melhor</li>
                                    <li>Desenvolver sua região</li>
                                    <li>Te ajudar em suas conquistas</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-type-cards">
                <div className="container-xxl">
                    <div className="card">
                        <div className="row">
                            <div className="col-12 col-lg-8 offset-lg-2 text-center">
                                <h2>Um cartão de crédito com benefícios exclusivos para você.</h2>
                                <p>Programa de recompensas e mais de 850 salas vips em aeroportos</p>
                            </div>
                            <div className="col-12 col-md-4 mt-5 position-relative ">
                                <div className="row">
                                    <div className="col-12">                                
                                        <img src={imgCartaoBlack} alt="icone Curtir" title="" />
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-9  mt-4">
                                        <h3>Cartão Sicredi Black</h3>
                                        <p>Um cartão para quem valoriza experiências únicas e ama viajar, com frequência. Tenha acesso a mais 850 salas vips pelo mundo.</p>
                                    </div>
                                    <div className="col-md-1 offset-md-1 text-center d-none d-lg-block">
                                        <div className="border"></div>
                                    </div>
                                    <div className="col-12">
                                        <a href="#" title=">Quero esse cartão"  className="btn btn-primary-outiline">Quero esse cartão</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 mt-5 position-relative">
                                <div className="row">
                                    <div className="col-12">
                                        <img src={imgCartaoPlatinum} alt="icone Curtir" title="" />
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-9 mt-4">
                                        <h3>Cartão Sicredi Platinum</h3>
                                        <p>Um cartão que acompanha seu estilo de vida. Faça compras com segurança e aproveite nosso programa de recompensa.</p>
                                    </div>
                                    <div className="col-md-1 offset-md-1 text-center d-none d-lg-block">
                                        <div className="border"></div>
                                    </div>
                                    <div className="col-12">
                                        <a href="#" title=">Quero esse cartão"  className="btn btn-primary-outiline">Quero esse cartão</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 mt-5 position-relative ">
                                <div className="row">
                                    <div className="col-12">
                                        <img src={imgCartaoGold} alt="icone Curtir" title="" />
                                    </div>
                                    <div className="col-12 mt-4">
                                        <div className="row">
                                            <div className="col-12 col-md-8">
                                                <h3>Cartão Sicredi Gold</h3>
                                            </div>
                                            <div className="col-11">
                                                <p>Aceito no Brasil e no exterior, esse cartão é ideal para quem precisa de segurança para suas compras nacionais e internacionais.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <a href="#" title=">Quero esse cartão" className="btn btn-primary-outiline">Quero esse cartão</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-invest">
                <div className="container-xxl">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-112 col-xl-11">
                                    <h2>Aqui você é dono, toma decisões e ainda contribui para uma sociedade mais próspera.</h2>
                                    <h3 className="mt-4">+ de R$390 milhões</h3>
                                    <p>Investidos em ações do Fundo de Assistência Técnica Educacional e Social (FATES), Fundo Social, Doações, Leis de Incentivo e Patrocínio Sociocultural.</p>
                                </div>
                                <div className="col-12 col-md-12 col-lg-11 col-xl-8">
                                    <h3 className="border-top">+ de Meio Milhão</h3>
                                    <p>de crianças e adolescentes impactados pelo Programa União Faz a Vida</p>
                                </div>
                                <div className="col-12">
                                    <a href="#" title=">Quero esse cartão" className="btn btn-primary-outiline">Quero esse cartão</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <img src={imgInvestimentos} className='img-fluid' title="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-footer footer">
                <div className="container-xxl">
                    <div className="row">
                        <div className="col-12">
                            <img src={logoFooter} className='img-fluid'  title="" />
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="box-contact-footer">
                                <h6>Whatsapp Corporativo</h6>
                                <span className="description">Todas as regiões</span>
                                <a href="tel:+555133584770" className="number">51 3358 4770</a>
                            </div>
                            <div className="box-contact-footer">
                                
                                <img src={imgQrCode} title="" />
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="box-contact-footer">
                                <h6>SAC</h6>
                                <span className="description">Informações, elogios e reclamações</span>
                                <a href="tel:08007247220" className="number">0800 724 7220</a>
                            </div>
                            <div className="box-contact-footer">
                                <h6>Ouvidoria</h6>
                                <span className="description">Reclamações e denúncias</span>
                                <a href="tel:08006462519" className="number">0800 646 2519</a>
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="box-contact-footer">
                                <h6>Atendimento à pessoas com deficiência</h6>
                                <span className="description">Auditivos ou de fala</span>
                                <a href="tel:08007247220" className="number">0800 724 0525</a>
                            </div>
                            <div className="box-contact-footer">
                                <h6>Chamadas Internacionais</h6>
                                <span className="description">(a cobrar)</span>
                                <a href="tel:+555133784472" className="number">+ 55 51 3378 4472</a>
                            </div>
                            
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="box-contact-footer">
                                <h6>Serviços por telefone</h6>
                                <span className="description">Capitais e regiões metropolitanas</span>
                                <a href="tel:30034770" className="number">3003 4770</a>
                            </div>
                            <div className="box-contact-footer">
                                <h6>Demais e regiões</h6>
                                <a href="tel:08007244770" className="number">0800 724 4770</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-reserved-rights">
                <div className="container-xxl text-center">
                    <div className="row">
                        <div className="col-12">
                            Todos os direitos reservados ao Sicredi - 2023
                        </div>
                    </div>
                </div>
            </section>
            <a id="scrollBtn" href="#" className="scroll-button">
                <img src={ArrowDropUpCircle}></img>
            </a>
        </div>
    );
}

export default Index;