import { useEffect, useState } from "react";
import { useToken } from "../../context/TokenContext";
import { useNavigate } from "react-router-dom";
import IntegrationService from "../../services/integration";
import * as signalR from "@microsoft/signalr";

function Account (){
    const { token } = useToken();
    const navigate = useNavigate(); 
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [messages, setMessages] = useState<string[]>([]);

    useEffect(() => {
        const connection = new signalR.HubConnectionBuilder()
            .withUrl("/uploadHub")
            .withAutomaticReconnect()
            .build();

        connection.on("ReceiveMessage", (message) => {
            setMessages(prevMessages => [...prevMessages, message]);
        });

        connection.start().catch((err) => console.error(err));

        return () => {
            connection.stop();
        };
    }, []);

    useEffect(() => {
        // Verifica se há um token antes de permitir o acesso à página Leads
        if (!token) {
          navigate('/')
        } else {
        }
    }, [token]);
    
    const handleFileChange = (event : any) => {
        setSelectedFile(event.target.files[0]);
      };

    const handleButtonUploadFile = () => {
        const batchSize = 10000;
        setErrorMsg('');
        setSuccessMsg('');
        setIsLoading(true);
        try {
            console.log(selectedFile)
            if (!selectedFile) return;
      
            const reader = new FileReader();
            reader.readAsText(selectedFile);
      
            reader.onload = async (event) => {
                const csvContent = event!.target!.result;
                if (typeof csvContent !== 'string') return;
        
                const lines = csvContent.split('\n').filter(Boolean);
                const header = lines[0]; // Extrair o cabeçalho do CSV
                const dataLines = lines.slice(1);

            
                for (let i = 0; i < dataLines.length; i += batchSize) {
                    const batch = dataLines.slice(i, i + batchSize);
                    const csvBatch = [header, ...batch].join('\n'); // Adicionar o cabeçalho ao lote
                    const base64 = btoa(unescape(encodeURIComponent(csvBatch)));
                    console.log(`Sent batch ${i / batchSize + 1} out of ${Math.ceil(dataLines.length / batchSize)}`);

                    await IntegrationService.uploadAccountsFile(base64, token!)
                        .then((response: any) => {
                            console.log(response);
                        });
            
                }          
                setSuccessMsg('CSV upload completado.')
                                
            };
      
            reader.onerror = (error) => {
                setErrorMsg('Erro na leitura do csv');
            };
          } catch (error) {
            setErrorMsg('Erro no envio do CSV');
          }
          setIsLoading(false);
    }   


    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                {/* Card Filter */}
                <div className="card">
                    <div className="row">
                    <div className="col-12">
                        <h6>                        
                        Upload arquivo de Contas
                        </h6>
                    </div>

                    <div className="col-12 col-lg-6">
                        <input type="file" id="file" onChange={handleFileChange}/>
                    </div>
                    {isLoading && (
                        <div className="spinner-border text-success" role="status">
                            <span className="sr-only"></span>
                        </div>
                    )}
                    <div className="col-12 col-lg-6">
                        {!isLoading && (
                            <button type="button" className="btn btn-primary w-100 mt-md-0" id="UploadFile" onClick={handleButtonUploadFile}>
                                <span>Fazer Upload</span>
                            </button>
                        )}
                    </div>
                    <div className="col-12 col-lg-6">
                        <ul>
                            {messages.map((message, index) => (
                                <li key={index}>{message}</li>
                            ))}
                        </ul>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="row">
                <span className="color-red">{errorMsg}</span>
                <span className="color-green">{successMsg}</span>
            </div>
        </div>
    );
}

export default Account;