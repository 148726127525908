import iconSmile from '../../images/icon-smile.png';

function AccountFinish (){

    return (
        <div className="msgs">
            <img src={iconSmile} alt="icone Não conseguimos" title="" />
            <h4>Ops! </h4>
            <h4>As informações de CPF e número de conta inseridas constam como uma conta encerrada no Sicredi.</h4>
            <p className="mb-0">Por favor confira as informações inseridas no site e tente novamente.</p>
            <p className="mb-0">Caso o erro persista, por gentileza contate-nos no WhatsApp através do número: 55 5133584770</p>
            <div className="col-md solicita-button">
                {/* <button type="button" className="btn btn-back w-100" onclick="BackInfo('-1')">Anterior</button> */}
            </div>
        </div>
    );
}

export default AccountFinish;