import { useState } from "react";
import { ClientView } from "../../models/client-view";
import { useSicrediContext } from "../../context/SicrediContext";

interface Props {
    cliente?: ClientView;
    onNext: () => void;
}

function Etapa1 (param: Props){
    const {setHaveAccount} = useSicrediContext();
    const [selectedOption, setSelectedOption] = useState<string>('Sim');

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(event.target.value);
        if(event.target.value == 'Sim')
            setHaveAccount(true);
        else
            setHaveAccount(false);
    };

    const newAccountClick = () => {
        window.location.href = 'https://www.sicredi.com.br/site/seja-associado/?utm_source=math_botao_lp&utm_medium=math_cpc&utm_campaign=cra-mt';
    }

    const next = () => {
        if(selectedOption == 'Sim')
            param.onNext();
        else
            newAccountClick();
    }

    return (
        <>
            <div className="divHasSicrediAccount mb-10">
                <div className="mb-0">
                    <label htmlFor="perguntaEassociado" className="form-label">Você já tem conta Sicredi?</label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="radioAssociado" id="flexRadioDefault1" value="Sim" checked={selectedOption === 'Sim'}
                            onChange={handleRadioChange}/>
                    <label className="form-check-label" htmlFor="flexRadioDefault1"> Sim </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="radioAssociado" id="flexRadioDefault2" value="Não" checked={selectedOption === 'Não'}
                            onChange={handleRadioChange}
                    />
                    <label className="form-check-label" htmlFor="flexRadioDefault2"> Não </label>
                </div>
            </div>
            <div>
                <div className="col-12 solicita-button">
                    <button type="button" className="btn btn-primary w-100" onClick={next}>Próximo</button>
                </div>
            </div>
        </>
    );
}

export default Etapa1;