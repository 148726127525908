import React, { createContext, useContext, useState } from 'react';
import { ClientView } from '../models/client-view';
import { Lead } from '../models/lead';
import { Account } from '../models/account';


interface SicrediContextType {
  haveAccount: boolean;
  validateSMS: boolean;
  clientView: ClientView;
  accounts: Account[];
  leadView: Lead;
  setLeadView: React.Dispatch<React.SetStateAction<Lead>>;
  setClientView: React.Dispatch<React.SetStateAction<ClientView>>;
  setAccounts: React.Dispatch<React.SetStateAction<Account[]>>;
  setValidateSMS: React.Dispatch<React.SetStateAction<boolean>>;
  setHaveAccount: React.Dispatch<React.SetStateAction<boolean>>;
}

const SicrediContext = createContext<SicrediContextType | undefined>(undefined);

export function useSicrediContext() {
  const context = useContext(SicrediContext);
  if (!context) {
    throw new Error('useClientView must be used within a ClientViewProvider');
  }
  return context;
}

export function SicrediViewProvider({ children }: { children: React.ReactNode }) {
  const [clientView, setClientView] = useState<ClientView>({
    id: null,
    numAg: null,
    ag: null,
    fcpfCgc: '',
    fconta: '',
    fcooperativa: '',
    fnome: null,
    fmunic: null,
    fuf: null,
    fativo: null,
    fendereco: null,
    femail: null,
    fcep: null,
    fenderecoNumero: null,
    fenderecoComplemento: null,
    fbairro: null,
    flimiteAtual: null,
    flimiteDisponivel: null,
    frenda: null,
    fcartao: null,
    fphone: null
  });

  const [leadView, setLeadView] = useState<Lead>({
    id: null,
    name: null,
    cardValue: null,
    segment: null,
    cooperative: null,
    document: null,
    cardType: null,
    account: null,
    postalCode: null,
    address: null,
    addressNumber: null,
    addressComplement: null,
    district: null,
    city: null,
    state: null,
    phone: null,
    phoneCode: null,
    prestamista: null,
    deliveryPlace: null,
    debitDay: null,
    creationDate: null,
    lastValidationDate: null,
    approved: null,
    validations: null,
    comprovedIncome: null,
    incomeValue: null,
    hasSicrediCreditCard: null,
    wantMoreLimit: null,
    email: null,
    hasAccountSicredi: null,
    whatsNotification: null,
    moreLimitValue: null,
    hasBeenExported: null,
    exportDate: null,
  });

  const [validateSMS, setValidateSMS] = useState<boolean>(false)

  const [haveAccount, setHaveAccount] = useState<boolean>(true)

  const [accounts, setAccounts] = useState<Account[]>([]);

  return (
    <SicrediContext.Provider value={{ clientView, setClientView, leadView, setLeadView, validateSMS, setValidateSMS, haveAccount, setHaveAccount, accounts, setAccounts }}>
      {children}
    </SicrediContext.Provider>
  );
}
