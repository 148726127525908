import { Navigate, useNavigate } from 'react-router-dom';
import { useToken } from '../../context/TokenContext';
import logoSicredi from '../../images/logo-sicredi.svg';
import IntegrationService from '../../services/integration';
import { useState } from 'react';

function Login (){
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isShowError, setIsShowErro] = useState(false);
    const { setToken } = useToken();
    const navigate = useNavigate(); 

    const handleLogin = async () => {
        try {
            const response = await IntegrationService.auth(email, password);
            if (response) {
                setToken(response.data.token);
                navigate('/leads'); 

            } else {
                setIsShowErro(true);
            }
        } catch (error) {
            console.error('Erro:', error);
        }
    }

    return (
        <>
            <input type="hidden" name="__RequestVerificationToken" value="your-token-value" />
            <section className="vh-100">
                <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                        <div className="card shadow-2-strong" style={{ borderRadius: '1rem' }}>
                            <div className="card-body p-5 text-center">
                            <div className="mb-5 text-center">
                                <img src={logoSicredi} alt="logo Sicredi" title="logo Sicredi" />
                            </div>
                            <div className="form-floating mb-4">
                                <input type="email" id="typeEmailX-2" className="form-control form-control-lg" value={email} onChange={(e) => setEmail(e.target.value)}/>
                                <label className="form-label" htmlFor="typeEmailX-2">Email</label>
                            </div>
                            <div className="form-floating mb-4">
                                <input type="password" id="typePasswordX-2" className="form-control form-control-lg" value={password} onChange={(e) => setPassword(e.target.value)}/>
                                <label className="form-label" htmlFor="typePasswordX-2">Password</label>
                            </div>
                            {isShowError && (
                                <p style={{ color: 'red' }}>Erro ao efetuar o login.</p>
                            )}
                            <button className="btn btn-primary btn-lg btn-block" type="submit" onClick={handleLogin}>Login</button>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Login;