import React from "react";
import "./MultiStepProgressBar.css";
import { ProgressBar, Step } from "react-step-progress-bar";

//https://codesandbox.io/s/onboarding-cdf8fg?file=/src/App.js
function MultiStepProgressBar ({ step }) {
  var stepPercentage = 0;
  if (step === 4) {
    stepPercentage = 25.3;
  } else if (step === 5) {
    stepPercentage = 77;
  } else if (step === 6) {
    stepPercentage = 100;
  }else{
    stepPercentage = 0;
  }

  return (
    <ProgressBar className="mb-5" percent={stepPercentage}>
      <Step>
        {({ accomplished, index }) => (
          <>
              <div
                className={`indexedStep ${accomplished ? "accomplished" : null}`}
                >
                    {index + 1}
              </div>
              <span className="span-info">Informações do Cartão</span>

          </>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <>
            <div
              className={`indexedStep ${accomplished ? "accomplished" : null}`}
              >
              {index + 1}
            </div>
            <span className="span-info">Informações Pessoais</span>
          </>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <>
            <div
              className={`indexedStep ${accomplished ? "accomplished" : null}`}
              >
              {index + 1}
            </div>
            <span className="span-info">Informações de Contato</span>
          </>
        )}
      </Step>
    </ProgressBar>
  );
};

export default MultiStepProgressBar;
