import iconCheck from '../../images/icon-check.png';

function SendSucess (){

    return (
        <div className="msgs">
            <img src={iconCheck} alt="icone Curtir" title="" />
            <h4>Dados enviados com sucesso.</h4>
            <p className="mb-0">Em breve você receberá, no WhatsApp do Sicredi, o status da sua solicitação.</p>
        </div>
    );
}

export default SendSucess;