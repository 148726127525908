import iconSmile from '../../images/icon-smile.png';

function DeniedRequest (){

    return (
        <div className="msgs">
            <img src={iconSmile} alt="icone Não conseguimos" title="" />
            <h4>Que pena, não conseguimos liberar um cartão para você.</h4>
            <p className="mb-0">Mas não desanime, uma nova análise para o cartão será feita dentro dos próximos 6 meses.</p>
        </div>
    );
}

export default DeniedRequest;