import { Account } from "../models/account";
import { Lead } from "../models/lead";

export function matchLeadWithAccount(lead: Lead, account: Account): Lead {
    return {
        ...lead,
        id: 0,
        account: account.fconta,
        name: account.fnome,
        document: account.fcpfCgc,
        postalCode: account.fcep,
        address: account.fendereco,
        addressNumber: account.fenderecoNumero,
        addressComplement: account.fenderecoComplemento,
        district: account.fbairro,
        city: account.fmunic,
        state: account.fuf,
        phone: account.fphone,
        hasAccountSicredi: 1,
        incomeValue: account.frenda, 
        cardType: account.fcartao,
        email: account.femail,
        deliveryPlace: 'Residencial', 
        cardValue: null, 
        segment: null, 
        cooperative: account.numAg, 
        phoneCode: null, 
        debitDay: null, 
        creationDate: null, 
        lastValidationDate: null, 
        approved: null, 
        validations: null, 
        comprovedIncome: null, 
        hasSicrediCreditCard: account.fcartao? 1 : 0, 
        wantMoreLimit: null, 
        whatsNotification: null, 
        moreLimitValue: null, 
        hasBeenExported: null, 
        exportDate: null,
    };
}