import { useEffect, useState } from "react";
import { useSicrediContext } from "../../context/SicrediContext";
import { Card } from "../../models/card";
import { DueDate } from "../../models/duedate";


interface Props{
    cards: Array<Card>;
    dueDates: Array<DueDate>;
    onNext: () => void;
    onPrev: () => void;
}

function convertCurrencyToNumber(currency: string): number {
    return Number(currency.replace('.', '').replace(',', '.'));
}

function Etapa1 (param: Props){
    const { leadView, setLeadView, accounts, clientView } = useSicrediContext();
    const [cards, setCards] = useState<Array<Card>>([]);

    useEffect(() => {
        let userIncomeNumber = convertCurrencyToNumber(clientView.frenda!);
        let suitableCards = param.cards.filter(card => userIncomeNumber >= convertCurrencyToNumber(card.minCardValue!));
        setCards(suitableCards);
    }, []);

    const handleCardTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {      
        const valor = event.target.value as string;
        console.log(valor);
        const newLeadView = {...leadView, cardType: valor}  
        setLeadView(newLeadView);
        return valor;
    };

    const handleDueDateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {      
        const valor = event.target.value as string;
        const newLeadView = {...leadView, debitDay: parseInt(valor, 10)}  
        setLeadView(newLeadView);
        return valor;
    };

    return (
        <>
            <div>
                <div className="mb-10">
                    <select
                        id="typeCardSelect"
                        className="form-select"
                        required
                        value={leadView.cardType || ''}
                        onChange={handleCardTypeChange}
                    >
                        <option value='' disabled>Qual tipo de cartão você deseja?</option>
                        {cards!.map((card) => (
                            <option key={card.cardName} value={card.cardName?.toString()}>
                                {card.cardName}
                            </option>
                        ))}
                    </select>
                    <div className="invalid-feedback">
                        Informe um tipo de cartão válido.
                    </div>
                </div>
                <div className="mb-10">
                    <select
                        id="citySelect"
                        className="form-select"
                        required
                        value={leadView.debitDay || ''}
                        onChange={handleDueDateChange}
                    >
                        <option value='' disabled>Data de vencimento da fatura</option>
                        {param.dueDates!.map((dueDate) => (
                            <option key={dueDate.due} value={dueDate.due?.toString()}>
                                {dueDate.due}
                            </option>
                        ))}
                    </select>
                    <div className="invalid-feedback">
                        Informe uma data de fatura válida.
                    </div>
                </div>
                <div className="mb-0">
                    <label htmlFor="receberCartao" className="form-label">Onde deseja receber o novo cartão?</label>
                </div>                
            </div>
            <div className="row mb-10">
                <div className="col-6 solicita-button">
                    <button type="button" className="btn btn-back w-100" onClick={param.onPrev}>Anterior</button>
                </div>
                <div className="col-6 solicita-button">
                    <button type="button" className="btn btn-primary w-100" onClick={param.onNext}>Próximo</button>
                </div>
            </div>
        </>
    );
}

export default Etapa1;