import React, { useState } from "react";
import { ClientView } from "../../models/client-view";
import { useSicrediContext } from "../../context/SicrediContext";

interface Props {
  cliente?: ClientView;
  onPrev: () => void;
  onFinish: () => void;
}

function Etapa5(param: Props) {
  const { leadView, setLeadView, clientView, setClientView } = useSicrediContext();
  const [isCheckTermo, setIsCheckTermo] = useState(false);
  const [isErrorCheck, setErrorCheck] = useState(false);
  const [isCheckWhats, setIsCheckWhats] = useState(false);
  const [isErrorCheckWhats, setErrorCheckWhats] = useState(false);

  const handleWhatsNotificationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsCheckWhats(event.target.checked);
    const valor = event.target.checked;
    const newLeadView = {...leadView, whatsNotification: valor ? 1 : 0, };
    setLeadView(newLeadView);
    return valor;
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    const newClientView = { ...clientView, fnome: value };
    setClientView(newClientView);
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    const newClientView = { ...clientView, fphone: value };
    setClientView(newClientView);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    const newClientView = { ...clientView, femail: value };
    setClientView(newClientView);
  };

  const handleTermoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsCheckTermo(event.target.checked);
  };

  const handleFinish = () => {
    if(!isCheckWhats){
      setErrorCheckWhats(true);
      return;
    }
    if (!isCheckTermo) {
        setErrorCheck(true);
      return;
    }
    setErrorCheck(false);
    setErrorCheckWhats(false);
    param.onFinish();
  };

  return (
    <>
      <div>
        <div className="form-floating mb-10">
          <input
            type="text"
            className="form-control"
            placeholder="Nome"
            value={param.cliente!.fnome || ''}
            onChange={e => handleNameChange(e)}
          />
          <label htmlFor="Nome">Nome</label>
          <span></span>
          <div className="invalid-feedback">
            Informe um nome.
          </div>
        </div>
        <div className="form-floating mb-10 divPersonalInformationJorn21">
          <input
            type="text"
            className="form-control"
            placeholder="Telefone"
            maxLength={15}
            value={param.cliente!.fphone || ''}
            onChange={e => handlePhoneChange(e)}
          />
          <label htmlFor="Telefone">Telefone</label>
          <div className="invalid-feedback">
            Informe o telefone.
          </div>
        </div>
        <div className="form-floating mb-10 divPersonalInformationJorn21">
          <input
            type="email"
            className="form-control"
            placeholder="Email"
            value={param.cliente!.femail || ''}
            onChange={e => handleEmailChange(e)}
          />
          <label htmlFor="Email">Email</label>
          <div className="invalid-feedback">
            Informe o email.
          </div>
        </div>
      </div>
      <div>
        <div className="form-check">
          <input
            className={`form-check-input ${
              isErrorCheckWhats ? "is-invalid" : ""
          }`}
            type="checkbox"
            id="whatsAppCheckJ3"
            onChange={handleWhatsNotificationChange}
          />
          <label
            className="form-check-label form-check-label-termo"
            htmlFor="whatsAppCheckJ3"
          >
            Você teria interesse em receber notificações via WhatsApp de nossa empresa sobre o seu pedido?
          </label>
          <div className="invalid-feedback">
              É necessário concordar com os termos para prosseguir.
          </div>
        </div>
        <div className="form-check">
          <input
            className={`form-check-input checkPrivacy ${
                isErrorCheck ? "is-invalid" : ""
            }`}
            type="checkbox"
            onChange={handleTermoChange}
            required
          />

          <label
            className="form-check-label form-check-label-termo"
            htmlFor="flexCheckTermoJ3"
          >
            Estou ciente e concordo com a <a href="https://www.sicredi.com.br/site/protecao-e-privacidade/" title="Política de Privacidade">Política de Privacidade</a> do Sicredi sobre o tratamento dos meus dados pessoais, ciente que o Sicredi consultará minhas informações de crédito e SCR e que farão contato comercial.
          </label>
          <div className="invalid-feedback">
              É necessário concordar com os termos para prosseguir.
          </div>
        </div>
      </div>
      <div className="row mb-10">
        <div className="col-6 solicita-button">
          <button
            type="button"
            className="btn btn-back w-100"
            onClick={param.onPrev}
          >
            Anterior
          </button>
        </div>
        <div className="col-6 solicita-button">
          <button
            type="button"
            className="btn btn-primary w-100"
            onClick={handleFinish}
          >
            Finalizar
          </button>
        </div>
      </div>
    </>
  );
}

export default Etapa5;
