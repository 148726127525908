import { useEffect, useState } from "react";
import { useSicrediContext } from "../../context/SicrediContext";
import { ClientView } from "../../models/client-view";
import { matchLeadWithAccount } from "../../Utils/Utils";

interface Props {
    onNext: () => void;
    onPrev: () => void;
}

function Etapa1(props : Props){
    const { clientView, setClientView, leadView, setLeadView, accounts } = useSicrediContext();
    const [isCardValid, setIsCardValid] = useState(true);
    
    useEffect(() => {
        if(leadView.moreLimitValue){
            const newLeadView = {...leadView}  
            setLeadView(newLeadView);
        }else{
            const newLeadView = {...leadView, moreLimitValue: clientView.flimiteAtual}  
            setLeadView(newLeadView);
        }
    }, []);
    
    const handleLimiteChange = (event: React.ChangeEvent<HTMLInputElement>) => {      
        const valor = event.target.value;
        const newLeadView = {...leadView, moreLimitValue: valor, wantMoreLimit: 1}  
        setLeadView(newLeadView);
        return valor;
    };

    const handleCardChange = (event : React.ChangeEvent<HTMLSelectElement>) => {
        const valor = event.target.value;
        let acc = accounts.filter(n => n.fcartao == valor)[0];
        console.log(acc)
        if(acc){
            var lead = matchLeadWithAccount(leadView, acc);
            const newLeadView = {...lead, cardType: valor, moreLimitValue: acc.flimiteAtual};
            setLeadView(newLeadView);
            const cv = {...clientView, flimiteAtual: acc.flimiteAtual, flimiteDisponivel: acc.flimiteDisponivel, moreLimitValue: acc.flimiteAtual};
            setClientView(cv);
        }
        
    };

    return (
        <>
            <div>
                <div className="mb-10 text-center">
                    <div className="mb-10">
                        <div className="mb-10">
                            <select
                                className={`form-select ${
                                isCardValid ? "" : "is-invalid"
                                }`}
                                value={leadView.cardType ? leadView.cardType : ''}
                                onChange={e => handleCardChange(e)}
                            >
                                <option value="" disabled>
                                Selecione um cartão
                                </option>
                                {accounts!.map((acc) => (
                                <option
                                    key={acc.fcartao}
                                    value={acc.fcartao!}
                                >
                                    {acc.fcartao}
                                </option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="perguntaEassociado" className="form-label">Selecione o valor do seu novo limite?</label>
                        </div>
                        <div className="text-center mb-10">
                            <div className="row">                        
                                <div className="col-12 mb-4">
                                    <span className="span-renda">R$ {leadView.moreLimitValue},00</span>
                                </div>
                                <div className="custom-range col-12">
                                    <input
                                        type="range"
                                        className="range-slider w-100"
                                        maxLength={15}
                                        placeholder="Valor"
                                        step={50}
                                        min={clientView!.flimiteAtual!}
                                        max={clientView!.flimiteDisponivel!}
                                        value={leadView.moreLimitValue!}
                                        required
                                        onChange={e => handleLimiteChange(e)}
                                    />
                                </div>
                                <div className="col-6 text-start">
                                    <span>R$ {clientView!.flimiteAtual!},00</span>
                                </div>       
                                <div className="col-6 text-end">
                                    <span className="">R$ {clientView!.flimiteDisponivel!},00</span>
                                </div>       
                                <div className="col-12 invalid-feedback">
                                    Informe um valor válido.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-10">
                <div className="col-6 solicita-button">
                    <button type="button" className="btn btn-back w-100" onClick={props.onPrev}>Anterior</button>
                </div>
                <div className="col-6 solicita-button">
                    <button type="button" className="btn btn-primary w-100" onClick={props.onNext}>Próximo</button>
                </div>
            </div>
        </>
    );
}

export default Etapa1;