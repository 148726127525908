import iconSmile from '../../images/icon-smile.png';

function Instability (){

    return (
        <div className="msgs">
            <img src={iconSmile} alt="icone Curtir" title="" />
            <h4>Ops! </h4>
            <p className="mb-0">Nossos serviços estão passando por instabilidade no momento.</p>
            <p className="mb-0">Por favor tente novamente em alguns minutos.</p>
        </div>
    );
}

export default Instability;