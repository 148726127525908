import { Lead } from "../../models/lead";

interface Props{
    leads?: Array<Lead>;
}

function TableLeads (param: Props) {

    return(
        <div className="row">
            <div className="col-12">
                <div className="table-responsive">
                <table className="table table-striped table-hover" id="dataTable">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Nome</th>
                        <th>CPF</th>
                        <th>Email</th>
                        <th>Cooperativa</th>
                        <th>Conta Sicredi</th>
                        <th>Cartão de Crédito Sicredi</th>
                        <th>Data de Criação</th>
                        <th>Segmento</th>
                        <th>Tipo de Cartão</th>
                        <th>Conta</th>
                        <th>CEP</th>
                        <th>Endereço</th>
                        <th>Número Endereço</th>
                        <th>Complemento Endereço</th>
                        <th>Distrito</th>
                        <th>Cidade</th>
                        <th>Estado</th>
                        <th>Telefone</th>
                        <th>Código Telefone</th>
                        <th>Prestamista</th>
                        <th>Local de Entrega</th>
                        <th>Dia de Débito</th>
                        <th>Última Data de Avaliação Serasa</th>
                        <th>Aprovado Serasa</th>
                        <th>Validações</th>
                        <th>Possui Renda Comprovada</th>
                        <th>Valor da Renda Informada</th>
                        <th>Deseja mais limite</th>
                        <th>Limite Solicitado</th>
                        <th>Notificações WhatsApp</th>
                    </tr>
                    </thead>
                    <tbody>
                        {param.leads?.map((value) => {
                            if (!value.hasBeenExported) {
                            return (
                                <tr key={value.id}>
                                <td>{value.id}</td>
                                <td>{value.name}</td>
                                <td>{value.document}</td>
                                <td>{value.email}</td>
                                <td>{value.cooperative}</td>
                                <td>{value.hasAccountSicredi === 1 ? 'Possui' : 'Não Possui'}</td>
                                <td>{value.hasSicrediCreditCard === 1 ? 'Possui' : 'Não Possui'}</td>
                                <td>{value.creationDate? new Date(value.creationDate).toLocaleString('pt-BR') : ''}</td>
                                <td>{value.segment}</td>
                                <td>{value.cardType}</td>
                                <td>{value.account}</td>
                                <td>{value.postalCode}</td>
                                <td>{value.address}</td>
                                <td>{value.addressNumber}</td>
                                <td>{value.addressComplement}</td>
                                <td>{value.district}</td>
                                <td>{value.city}</td>
                                <td>{value.state}</td>
                                <td>{value.phone}</td>
                                <td>{value.phoneCode}</td>
                                <td>{value.prestamista}</td>
                                <td>{value.deliveryPlace}</td>
                                <td>{value.debitDay}</td>
                                <td>{value.lastValidationDate!}</td>
                                <td>{value.approved === 1 ? 'Sim' : 'Não'}</td>
                                <td>{value.validations}</td>
                                <td>
                                    {value.comprovedIncome === 1
                                    ? 'Sim'
                                    : value.comprovedIncome === 0
                                    ? 'Não'
                                    : ''}
                                </td>
                                <td>{value.incomeValue}</td>
                                <td>
                                    {value.wantMoreLimit === 1
                                    ? 'Sim'
                                    : value.wantMoreLimit === 0
                                    ? 'Não'
                                    : ''}
                                </td>
                                <td>R$ {value.moreLimitValue}</td>
                                <td>
                                    {value.whatsNotification === 1
                                    ? 'Sim'
                                    : value.whatsNotification === 0
                                    ? 'Não'
                                    : ''}
                                </td>
                                </tr>
                            );
                            } else {
                                return null; // Don't render if hasBeenExported is true
                            }
                        })}
                    </tbody>

                </table>
                </div>
            </div>
        </div>
    )
}

export default TableLeads;