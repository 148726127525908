import { useToken } from "../context/TokenContext";
import { Account } from "../models/account";
import { Agency } from "../models/agency";
import { Card } from "../models/card";
import { Cooperative } from "../models/cooperative";
import { DueDate } from "../models/duedate";
import { Lead } from "../models/lead";
import { State } from "../models/state";
import http from './http-common';

const getAllAgency = () => {
    return http.get<Array<Agency>>("/Agency");
}
const getAllCooperative = () => {
    return http.get<Array<Cooperative>>("/Cooperative");
}

const getAllStates = () => {
    return http.get<Array<State>>("/State");
}

const getAllCities = (uf : string) => {
    return http.get("https://servicodados.ibge.gov.br/api/v1/localidades/estados/" + uf + "/municipios?orderBy=nome");
}

const getAllCards = (token: string) => {

    return http.get<Array<Card>>("/Card", {headers: {Authorization: `Bearer ${token}`}});
}

const getAllDueDates = () => {
    return http.get<Array<DueDate>>("/DueDate");
}

const getAllAccount = (token : string) =>{
    return http.get<Account[]>("Account/getallaccountbyuser/", {headers: {Authorization: `Bearer ${token}`}})
}

const verifyAccount = (cpf: string, conta: string, cooperativa: string) =>{
    return http.get<boolean>("Account/" + cpf + "/" + conta + "/" + cooperativa)
}

const getLeads = (startDate: string, endDate: string, cooperativa: number, token: string) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json"
      };
    
    return http.get<Array<Lead>>("Leads/" + startDate.toString() + "/" + endDate.toString() + "/" + cooperativa, {headers})
}

const addLead = (lead: Lead, token: string) =>{
    return http.post<any>("Leads", lead, {headers: {Authorization: `Bearer ${token}`}})
}

const setFlag = (leads: Array<Lead>, token: string) =>{
    return http.post<any>("Leads/SetExportFlag", leads, {headers: {Authorization: `Bearer ${token}`}});
}

const sendSMS = (doc: string, token: string) =>{
    return http.post<any>("Account/SendSMS/", doc, {headers: {Authorization: `Bearer ${token}`}});
}

const validateSMS = (cpf: string, code: string, token: string) =>{
    return http.post<any>("Account/ValidateSMSCode/", {
        cpf: cpf,
        code: code
    }, {headers: {Authorization: `Bearer ${token}`}});
}

const auth = (login: string, password: string) =>{
    const content = {
        Email: login,
        Password: password
    }
    return http.post<any>("Login/", content);
}

const uploadAccountsFile = (base64 : string, token : string) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json"
    };

    const content = {
        base64file: base64,
    }
    return http.post<any>("Account/", content, {headers});
}

const IntegrationService = {
    getAllAgency,
    getAllCooperative,
    getAllStates,
    getAllCities,
    getAllCards,
    getAllAccount,
    getAllDueDates,
    verifyAccount,
    getLeads,
    addLead,
    setFlag,
    sendSMS,
    validateSMS,
    auth,
    uploadAccountsFile
  };
  
  export default IntegrationService;