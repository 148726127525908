import { useEffect, useState } from "react";
import { ClientView } from "../../models/client-view";
import { useSicrediContext } from "../../context/SicrediContext";
import IntegrationService from "../../services/integration";
import { useToken } from "../../context/TokenContext";

interface Props {
  cliente?: ClientView;
}

function VerifySMS (param: Props){
  const { clientView, setValidateSMS } = useSicrediContext();
  const [code, setCode] = useState('');
  const [messageError, setMessageError] = useState(false);
  const [timer, setTimer] = useState(8 * 60);
  const [timerInterval, setTimerInterval] = useState<NodeJS.Timeout | null>(null);
  const { token, setToken } = useToken();
  const [newToken, setNewToken] = useState('');

  const startTimer = () => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);
    setTimerInterval(interval);
  };

  const resetTimer = () => {
    clearInterval(timerInterval!);
    setTimer(8 * 60);
  };

  useEffect(() => {    
    startTimer();
    return () => {
      if (timerInterval) {
        clearInterval(timerInterval);
      }
    };
  }, []);

  useEffect(() => {
    if(newToken){
      setValidateSMS(true);
      setMessageError(false);
      setToken(newToken);
    }
  }, [newToken]);

  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, ''); // Remove caracteres não numéricos
    setCode(numericValue);
    return numericValue;
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      validarSMS();
    }
  }; 

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    validarSMS();
  };

  const validarSMS = () => {
    IntegrationService.validateSMS(clientView.fcpfCgc!, code, token!)
      .then((response: any) =>{
        if(response.data.data){
          setNewToken(response.data.token);
        }else{
          setMessageError(true);
        }
      }).catch((error : any) => {
        setMessageError(true);
      });
  }

  const functionSendSMS = () => {
    resetTimer();
    IntegrationService.sendSMS(clientView.fcpfCgc!, token!)
      .then((response: any) => {
        if(!response){
          
        }
      }).catch((reason : any)=> {
        
      })
  }

  return (
    <>
      <p className="text-center mb-2">Digite o código de 6 dígitos recebido via SMS, caso não tenha recebido, clique em <button className="link-reenvio" type="button" onClick={functionSendSMS}>Reenviar Código</button></p>
      <p className="text-center mb-2">Após o termino do tempo seu código não será mais válido</p>
      <p className="text-center">{Math.floor(timer / 60).toString().padStart(2, '0')}:{(timer % 60).toString().padStart(2, '0')}</p>
      <div className="row justify-content-center needs-validation">
        <div className="col-6">
          <input className="form-control" type="text" maxLength={6} value={code} onChange={handleCodeChange}  onKeyDown={handleKeyDown}/>
        </div>
        <div className="col-5">
          <button className="btn btn-primary w-100 mt-0" type="button" onClick={handleSubmit} disabled={timer === 0 || code.length !== 6}>Enviar</button>
        </div>
        <div className="col-12 text-center mt-2 ">
          <p>{messageError && (<span className="span-error">Código informado inválido.</span>)}</p>
        </div>
      </div>
    </>
  );
}

export default VerifySMS;