import { useEffect, useState } from "react";
import { useToken } from "../../context/TokenContext";
import { useNavigate } from "react-router-dom";
import logoSicredi from '../../images/logo-sicredi.svg';
import iconUser from '../../images/icon-user.svg';
import IntegrationService from "../../services/integration";
import { Cooperative } from "../../models/cooperative";
import TableLeads from "./TableLeads";
import { Lead } from "../../models/lead";
import Account from "../Account/Account";

function LeadsIndex (){
    const { token, clearCache } = useToken();
    const navigate = useNavigate(); 
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 1);

    const [startDate, setStartDate] = useState<Date>(currentDate);
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [cooperativaId, setCooperativaId] = useState(-1);
    const [cooperativas, setCooperativas] = useState<Array<Cooperative>>();
    const [leads, setLeads] = useState<Array<Lead>>();
    const [isHome, setIsHome] = useState(true);
    useEffect(() => {
        // Verifica se há um token antes de permitir o acesso à página Leads
        if (!token) {
          navigate('/')
        } else {

            IntegrationService.getAllCooperative().then((response : any) => {
                setCooperativas(response.data);
            });

            const formattedStartDate = startDate.toISOString().split('T')[0];
            const formattedEndDate = endDate.toISOString().split('T')[0];
            IntegrationService.getLeads(formattedStartDate, formattedEndDate, cooperativaId, token).then((response : any) => {
                setLeads(response.data);
            });

        }
    }, [token]);

    const handleCooperativeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setCooperativaId(parseInt(event.target.value));
        return event.target.value;
      };
    
    const handleFilterSubmit = () => {
        const formattedStartDate = startDate.toISOString().split('T')[0];
        const formattedEndDate = endDate.toISOString().split('T')[0];
        IntegrationService.getLeads(formattedStartDate, formattedEndDate, cooperativaId, token!).then((response : any) => {
            setLeads(response.data);
        });
    };
    
    const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedDate = new Date(e.target.value);
        setStartDate(selectedDate);  
    };

    const handleDownload = () => {
        const table = document.getElementById('dataTable') as HTMLTableElement;
        if (table) {
          const csvContents = [].map.call(table.rows,
            (tr: HTMLTableRowElement) => [].map.call(tr.cells, (td: HTMLTableCellElement) => td.textContent).join(';')
          ).join('\n');
      
          const blob = new Blob(["\ufeff", csvContents]);
          const url = URL.createObjectURL(blob);
      
          const downloadLink = document.createElement("a");
          downloadLink.href = url;
          downloadLink.target = "_blank";
          downloadLink.download = "Leads.csv";
          downloadLink.rel = "noopener noreferrer"; 
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          console.log(leads);
          IntegrationService.setFlag(leads? leads : [], token!)
            .then((response: any) => {

            }).catch((reason : any) => {

            });
        }
      };
      
    const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedDate = new Date(e.target.value);
        setEndDate(selectedDate);
    };

    const handleHrefPageHome = ()=> {
        setIsHome(true);
    }

    const handleHrefPageAccount = ()=> {
        setIsHome(false);
    }

    const handleLogOut = () =>{
        clearCache();
    };

    return (
        <>
            <form method="post">
                <header className="header">
                    <div className="container">
                    <div className="row">
                        <div className="col-4 text-center text-sm-start">
                        <img src={logoSicredi} alt="logo Sicredi" title="logo Sicredi" />
                        </div>
                        <div className="col-4 text-center text-sm-start">
                        <nav className="nav">
                            <a className="cursor-pointer nav-link" onClick={handleHrefPageHome}>Home</a>
                            <a className="cursor-pointer nav-link" onClick={handleHrefPageAccount}>Contas</a>
                        </nav>
                        </div>
                        <div className="col-4 text-center text-sm-end">
                        <div className="box-login">
                            <img className="d-none d-sm-inline-block" src={iconUser} alt="Icon User" title="" />
                            <div className="info-user">
                            <span className="name-user text-sm-start text-end">Conta do Sistema</span>
                                <a className="cursor-pointer link-logout text-sm-start text-end" onClick={handleLogOut}>Sair</a>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </header>
                <main>
                    {isHome && (
                        <>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h6>Filtrar Leads</h6>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <div className="form-floating mb-1 mb-md-0">
                                                    <input
                                                        type="date"
                                                        value={startDate?.toISOString().split('T')[0]}
                                                        onChange={handleStartDateChange}
                                                        className="form-control date-range-filter"
                                                        placeholder="Inicio - dd/mm/aaaa"
                                                    />
                                                    <label htmlFor="floatingInputDataInicio">Inicio - dd/mm/aaaa</label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <div className="form-floating mb-1 mb-md-0">
                                                        <input
                                                            type="date"
                                                            value={endDate?.toISOString().split('T')[0]}
                                                            onChange={handleEndDateChange}
                                                            className="form-control date-range-filter"
                                                            placeholder="Fim - dd/mm/aaaa"
                                                        />
                                                        <label htmlFor="floatingInputDataInicio">Fim - dd/mm/aaaa</label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <div className="mb-1 mb-md-0">
                                                        <select className="form-select" value={cooperativaId} onChange={handleCooperativeChange}>
                                                            <option value={-1} disabled>
                                                            Selecione uma cooperativa
                                                            </option>
                                                            <option value={0}>
                                                                Todos
                                                            </option>
                                                            {cooperativas?.map((cooperative: Cooperative) => (
                                                            <option key={cooperative.id} value={cooperative.id?.toString()}>
                                                                {cooperative.id!.toString() + ' - ' + cooperative.cooperativeName}
                                                            </option>
                                                            ))}
                                                        </select>
                                                        <div className="invalid-feedback">Informe a Cooperativa.</div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary w-100 mt-md-0"
                                                        onClick={handleFilterSubmit}
                                                        >
                                                        Aplicar
                                                    </button>
                                                </div>
                                            </div>                                    
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="card p-0 mb-4">
                                        <div className="header-card">
                                            <div className="row">									
                                                <div className="col-12 text-end">
                                                    <button className="btn btn-primary-outiline mt-md-0" type="button" onClick={handleDownload}>Exportar para excel</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="body-card" id="tableresponse">
                                            <TableLeads leads={leads}/>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                            <div className="modal fade" id="ModalScrollable" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="ModalScrollable" aria-hidden="true" />
                        </>
                    )}
                    {!isHome &&(
                        <>
                            <Account/>
                        </>
                    )}
                    
                </main>
            </form>
        </>
    );
}

export default LeadsIndex;