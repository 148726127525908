import iconSmile from '../../images/icon-smile.png';

function NotLocalized (){

    return (
        <div className="msgs">
            <img src={iconSmile} alt="icone Curtir" title="" />
            <h4>Ops! </h4>
            <h4>Não localizamos suas informações de CPF e número de conta no Sicredi.</h4>
            <p className="mb-0">Por favor confira as informações inseridas no site e tente novamente.</p>
            <p className="mb-0">Caso o erro persista, por gentileza contate-nos no WhatsApp através do número: 55 5133584770</p>            
        </div>
    );
}

export default NotLocalized;