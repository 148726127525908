import ReactGA from 'react-ga4';
import generateClientIdGa from './generate-client-id-ga';

export const gaEnvKey = 'G-5Q3KNXV7XQ';

const ga = {
    initGoogleAnalytics() {
        const trackingId = process.env[gaEnvKey];
        if (!trackingId) console.warn("No tracking id is found for Google Analytics")

        console.log(trackingId)
        ReactGA.initialize([
            {
                trackingId: 'G-5Q3KNXV7XQ',
                gaOptions: {
                    anonymizeIp: true,
                    clientId: generateClientIdGa(),
                    // testMode: true
                },
            }
        ]);
    }
};

export default ga;